import React, { useState, useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'

import PageWrapper from './page_wrapper'
import PersonForm from './person_form'
import Steps from './steps'

export default function Consent() {
  const [scrolledBottom, setScrolledBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [personForm, setPersonForm] = useState(false)
  const navigate = useNavigate()

  const {
    handleLogout,
    state
  } = useContext(AppContext)

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
  }, [state.loginJWT, state.webJWT, navigate])

  const showConsentForm = () => {
    if (state.profLoading) {
      return (
        <Box
          sx={{
            p: 2,
            minHeight: '10rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={30} />
        </Box>
      )
    }

    if (state.profile) {
      return (
        <Box width={{ lg: 600, sm: 600, xs: 320 }}>
        <Typography variant='body1' color='text.secondary'>
          You have given your consent for this VantageScore4<sup>plus</sup> Demo.
        </Typography>
        <br /> <br />
        <Typography variant='body1' color='text.secondary'>
          Your decision to participate in this VantageScore4<sup>plus</sup> Demo is completely voluntary
          and you may decline to participate or stop your participation at any time during
          this user experience demonstration by exiting the user experience and
          contacting <b>vs4plus@vantagescore.com</b> or <b>support@pentadatainc.com</b> to
          remove your personal information.
        </Typography>
          <br />
        <LoadingButton
          variant='customContained'
          onClick={() => {
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
              navigate('/info')
            }, [1000])
          }}
          disabled={false}
          loading={loading}
        >
          Next step
        </LoadingButton>
        </Box>
      )
    }

    if (personForm) {
      return (
        <PersonForm />
      )
    }

    return (
      <Box width={{ lg: 600, sm: 600, xs: 320 }}>
        <Typography
          color='primary'
          fontWeight={600}
          textAlign='center'
          fontSize={{ lg: '1.25rem', xs: '1.1rem' }}
        >
          Consent to participate in VantageScore4<sup>plus</sup> Pilot program
          <br />
          Part 1 of 2
        </Typography>
        <Divider sx={{ height: '2rem', border: 'none' }} />
        <Typography variant='body1' color='text.secondary' component='div'>
          Thank you for your interest in participating in this Pilot of VantageScore4<sup>plus</sup>. Review carefully the terms below, then decide if you want to continue the Pilot, or to opt-out.
        </Typography>
        <br />
        <Box
          sx={{
            maxHeight: '55vh',
            overflowY: 'scroll',
            padding: '0.5rem',
            width: '100%',
            maxWidth: '100%',
            fontFamily: 'Times New Roman'
          }}
          onScroll={e => {
            const delta = 20;
            console.log('=====');
            console.log(e.target.clientHeight);
            console.log(e.target.scrollHeight);
            console.log(e.target.scrollTop);
            const diff = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
            console.log(diff);
            if (diff > -1 * delta && diff < delta) {
              setScrolledBottom(true)
            } else {
              setScrolledBottom(false)
            }
          }}
          onChange={() => console.log(scrolledBottom)}
        >
          <Typography variant='h7' align='center' color='text.secondary' component='div'>
            <b>PILOT INFORMED CONSENT FORM</b>
            <br />
            <b>Pilot Organizer</b>: Pentadata, Inc. - support@pentadatainc.com
            <br />
            <b>Pilot Sponsor</b>: VantageScore Solutions, LLC - vs4plus@vantagescore.com
          </Typography>
          <br />

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          VantageScore Solutions, LLC ("Company") is providing this VantageScore4<sup>plus</sup> Demo solely for
          demonstration purposes and is only for illustrative and personal informational use.
          By using this VantageScore4<sup>plus</sup> Demo, you accept and agree to the following terms of use ("Terms of Use").
          If you do not agree with these Terms of Use, please do not use the VantageScore4<sup>plus</sup> Demo.
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          The content, functionality, and materials related to this VantageScore4<sup>plus</sup> Demo
          (the "Demo Materials") have not been publicly announced or generally released by Company,
          and Company makes no representation or guarantee that any Demo Materials will be released
          or available in this form or otherwise.
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          You are not granted the right to license, republish, distribute, copy, assign, sublicense, transfer, sell,
          prepare any derivative works or other use of any aspect of the VantageScore4<sup>plus</sup> Demo.
          Company retains all right, title and interest in and to all Demo Materials and shall own all copies,
          modifications and derivatives thereof.  You shall not copy, modify, reverse engineer, decompile,
          disassemble or distribute any software incorporated into or provided with the Demo Material.
          The intellectual and technical concepts contained herein are proprietary to Company and may be
          covered by U.S. Patents and patents in process, and are protected by trade secret or copyright law.
          You shall not remove or alter any names, markings or notices on the Demo Material.
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          You may obtain nonpublic information relating to the Demo Materials, Company’s product roadmap,
          development and release plans, clients and their operations and/or Company’s business model, operations
          or know-how (collectively, "Confidential Information"). Such Confidential Information shall
          belong solely to Company. You shall maintain Confidential Information in confidence and not use
          or disclose Confidential Information to any third party without the prior written consent of Company,
          until such time any Confidential Information is or becomes part of the public domain or is
          received by You through alternative means without breach of any confidentiality obligations by
          the provider of the information.
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          You are not permitted to (i) use the VantageScore4<sup>plus</sup> Demo for any credit decision purposes,
          or (ii) forward any links to the demonstration without prior written consent from Company.
          In no event should the VantageScore 4+ Demo be used in any manner related to a consumer credit decision,
          to obtain any information from a consumer, or provide a VantageScore credit score to a consumer.
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          To participate in the VantageScore 4+ Demo you will need to provide access to your demand deposit
          account at a financial institution and your unfrozen credit report with each of the national credit
          reporting agencies (NCRAs).  Your decision to participate in this VantageScore4<sup>plus</sup> Demo is completely
          voluntary and you may decline to participate or stop your participation at any time. 
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          During the course of the VantageScore4<sup>plus</sup> Demo, you will be asked to provide identifying
          information for the purposes of verifying your identity and to obtain your credit report
          and score from the NCRAs. Information you provide is for demonstration and informational
          purposes only and not related to any credit decision and <b>will not impact your credit score</b>.
          Company is administering the VantageScore4<sup>plus</sup> Demo through Pentadata, Inc. ("Pentadata") and
          your information will be submitted directly to Pentadata. You will be asked to acknowledge
          Pentadata's <a href="https://www.pentadatainc.com/privacy-policy">Privacy Policy</a>.
          You will also be asked to connect a financial institution demand
          deposit account through the services of a third-party data aggregator and may be asked to consent
          to the third-party data aggregators terms and privacy policy. <b>Company does not have access to your
          identifying information, credit score information, or third-party data you may share during
          the course of the VantageScore4<sup>plus</sup> Demo.</b>
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          You may provide ideas, suggestions, information, and other feedback (collectively, "Feedback")
          relating to Company's solutions, products, features, services or the Demo Materials.
          You agree that Company may use and incorporate any Feedback you provide without
          payment or attribution. Company is not obligated to use or incorporate any Feedback
          you provide. Please submit feedback to <b>vs4plus@vantagescore.com</b>
          </p>
          </Typography>

          <Typography variant='body1' color='text.secondary' component='div'>
          <p>
          THE VANTAGESCORE4<sup>plus</sup> DEMO AND DEMO MATERIALS ARE PROVIDED AS IS. COMPANY DISCLAIMS ALL
          WARRANTIES RELATING TO THE DEMO MATERIALS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
          ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY SHALL NOT
          BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY DEMO MATERIALS OR THESE TERMS OF USE UNDER
          ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY FOR ANY DIRECT, INDIRECT,
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING, WIHOUT LIMITATION, LOSS OF REVENUES, PROFITS OR DATA.
          </p>
          </Typography>

          <Typography variant='body' color='text.secondary' component='div'>
            <b><u>CONSENT</u></b>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            By signing this Consent, I acknowledge and agree:
            <ol>
              <li>I have carefully read this Consent, which is written in English, and English is a language that I read and understand.</li>
              <li>I understand this Consent and have received answers to my questions.</li>
              <li>I am voluntarily signing this Consent indicating that I consent to participate in this Pilot.</li>
              <li>I agree that I will keep confidential all information disclosed to me during the Pilot.</li>
              <li>I agree to the collection, use, sharing, disclosure, transfer, including transfer to other countries,
                and maintenance of my Pilot Data (including Coded PIlot Data) as described in this Consent.</li>
              <li>I understand that I can withdraw from the Pilot at any time.</li>
              <li>I understand that I may ask for a copy of this Consent.</li>
            </ol>
          </Typography> <br />
          <Typography variant='body' color='text.secondary' component='div'>
            If this is an electronic consent I understand that by clicking accept or typing my name and
            the date below I am providing my consent electronically and that it has the same force and effect as
            if I was signing in person on paper.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: {
              lg: 'row',
              xs: 'column'
            },
            justifyContent: 'center',
            '& button': {
              ml: {
                lg: 3,
                xs: 0
              },
              mt: {
                lg: 0,
                xs: 2
              },
              minWidth: '10rem'
            }
          }}
        >
          <LoadingButton
            variant={scrolledBottom ? 'customContained' : 'customContainedDisabled'}
            onClick={() => {
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                setPersonForm(true)
              }, [1000])
            }}
            disabled={!scrolledBottom}
            loading={loading}
          >
            OK, I consent
          </LoadingButton>
          <Button
            variant='customContainedDisabled'
            onClick={() => handleLogout()}
          >
            No, log me out
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <PageWrapper>
      <Box
        component='main'
        sx={{
          minHeight: '90vh',
          width: '100%',
          bgcolor: 'background.default',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: {
            lg: 'center',
            sm: 'flex-start',
            xs: 'flex-start'
          },
          alignItems: 'center'
        }}
      >
        <Toolbar />
        <Steps />
        <Divider sx={{ height: '2rem', border: 'none' }} />
        {showConsentForm()}
      </Box>
    </PageWrapper>
  )
}
