import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import AppBar from '@mui/material/AppBar'

const style = {
  footer: {
    display: 'flex',
    justifyContent: {
      lg: 'space-between',
      sm: 'space-between',
      xs: 'center'
    },
    alignItems: 'center',
    px: 2,
    height: 80,
    background: '#fff',
    flexDirection: {
      lg: 'row',
      sm: 'row',
      xs: 'column'
    }
  },
  text: {
    fontSize: '.9em',
    color: theme => theme.palette.primary.main
  }
}

export default function Footer() {
  return (
    <AppBar
      position='relative'
      sx={style.footer}
      elevation={0}
    >
      <Box>
        <Typography sx={style.text}>
          2024 VantageScore Solutions, LLC. All Rights Reserved.
        </Typography>
      </Box>
      <Box display='flex' flexDirection='row'>
        <Typography sx={style.text} mx={1}>
          <Link href='https://www.pentadatainc.com/privacy-policy' target='_blank'>
            PRIVACY POLICY
          </Link>
        </Typography>
        <Typography sx={style.text} mx={1}>
          <Link href='https://www.pentadatainc.com/terms-of-service' target='_blank'>
            TERMS OF USE
          </Link>
        </Typography>
      </Box>
    </AppBar>
    // <Box mb={0}>
    //   <Box sx={style.footer}>
    //     <Box>
    //       <Typography sx={style.text}>
    //         2023 VantageScore Solutions, LLC. All Rights Reserved.
    //       </Typography>
    //     </Box>
    //     <Box display='flex'>
    //       <Typography sx={style.text} mx={1}>
    //         <Link href='https://www.vantagescore.com/privacy-policy/' target='_blank'>
    //           PRIVACY POLICY
    //         </Link>
    //       </Typography>
    //       <Typography sx={style.text} mx={1}>
    //         <Link href='https://www.vantagescore.com/terms-of-use/' target='_blank'>
    //           TERMS OF USE
    //         </Link>
    //       </Typography>
    //     </Box>
    //   </Box>
    // </Box>
  )
}
