import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import api from '../../util/api'
import centerImg from '../../images/page1_center.svg'

import PageWrapper from './page_wrapper'

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: { lg: 'calc(100vh - 80px)', sm: 'calc(100vh - 140px)', xs: '90vh' },
    width: '100%'
  },
  content: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { lg: 'row', xs: 'column' },
    '& img': {
      width: { lg: 480, sm: 400, xs: 340 }
    }
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    // width: 600,
    height: 400,
    alignItems: { lg: 'flex-start', xs: 'center' }
  },
  loginBody: {
    display: 'flex',
    flexDirection: 'column',
    px: 1,
    // height: 500,
    width: 350,
    alignItems: 'center',
    textAlign: { lg: 'left', xs: 'center' }
  },
  loginField: {
    width: 240,
    height: 70,
    '& .MuiInputBase-root': {
      borderRadius: '24px',
    },
    '& input': {
      textAlign: 'center',
      fontSize: '.9em'
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: { lg: 'flex-start', xs: 'center' }
  }
}

export default function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const { webJWT, showAlert } = useContext(AppContext)

  useEffect(() => {
    if (webJWT) {
      navigate('/')
    }
  }, [webJWT, navigate])

  const handleLogin = async () => {
    if (!email) return
    const res = await api.login(email)
    if (!res) {
      showAlert('There was a problem with your request.')
      return
    }
    if (res.status === 200) {
      // showAlert('Please check your email for login link.')
      setEmailSent(true)
    } else {
      showAlert(res.data.message
        ? res.data.message
        : 'There was a problem with your request.'
      )
    }
  }

  const renderLogin = () => {
    if (emailSent) {
      return (
        <Box sx={style.loginBody}>
          <Typography
            color='text.secondary'
            sx={{ my: 4 }}
          >
            Your VantageScore4<sup>plus</sup> journey awaits...
          </Typography>
          <Typography
            color='text.secondary'
            sx={{ width: { lg: 450, xs: 'auto' } }}
          >
            Secured log in instructions have been sent to your email address. <br /><br />
            Please check your email inbox (or junk folder) to access your account.
          </Typography>
        </Box>
      )
    }
    return (
      <Box sx={style.loginBody}>
        <Typography
          color='text.secondary'
          sx={{ my: 4 }}
        >
          Begin your VantageScore4<sup>plus</sup> journey by entering your email
        </Typography>
        <Box sx={style.flexCenter}>
          <TextField
            fullWidth
            placeholder='Email'
            variant='outlined'
            type='email'
            size='small'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={style.loginField}
          />
        </Box>
        <Button
          variant='customContained'
          sx={{ m: '0.3em auto' }}
          onClick={handleLogin}
          // disabled={email ? false : true}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    )
  }
  return (
    <PageWrapper>
      <Box sx={style.container}>
        <Box sx={style.content}>
          <img src={centerImg} alt='center' />
          <Box sx={style.login}>
            <Typography
              // variant='h4'
              color='primary'
              fontWeight={600}
              fontSize={{ lg: 34, xs: 28 }}
              width={320}
              textAlign={{ lg: 'left', xs: 'center' }}
            >
              Unlock Your Financial Potential with VantageScore4<sup>plus</sup>
            </Typography>
            {renderLogin()}
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  )
}
